.portfolio {
  height: fit-content !important;
  font-family: "Montserrat";
  width: 95%;
  margin-inline: auto;
  margin-bottom: 2rem;
  --transition: all 400ms ease-in-out;
  --card-color-primary: var(--color-black);
  --card-color-primary-bg: #c58e6048;
  --line-width: 1px;
}

@media (max-width: 320px) {
  .portfolio {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.card-container {
  grid-gap: 2.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

@media (min-width: 1900px) {
  .card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width <= 600px) {
    .portfolio {
      width: 90%;
    }
}

.card {
  background-color: var(--card-color-primary-bg);
  padding: 10px;
  transition: var(--transition);
  border-radius: 15px;
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 6;
  gap: 0;
  color: var(--color-white);
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}

.card .image,
.card .image figure {
  margin: 0;
}

.card .image {
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
}

.card .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.card .title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  margin: 5px 0;
  line-height: 2em;
}

.card hr {
  width: 100%;
  margin: 0;
  border: 0;
  height: var(--line-width);
  background: var(--card-color-primary);
  margin-top: 5px;
  margin-bottom: 5px;
}

.card .title-container .title {
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  font-weight: 500;
  letter-spacing: -0.3px;
}

.card .title-container nav.links ul {
  display: flex;
  gap: 5px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.card .title-container nav.links li a.link-button {
  color: (--color-black);
  padding: 0 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 300;
  font-size: 14px;
  border: var(--line-width) solid var(--card-color-primary);
  background-color: grey;
  display: inline-block;
}

.card .title-container nav.links li a.link-button:hover {
  background-color: transparent;
  border: var(--line-width) solid var(--card-color-primary);
}

.card .description {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 5px 0;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5rem;
}

.card .technologies {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 5px 0;
  font-weight: 300;
  font-size: 14px;
}

.technologies .col li {
  margin: 0;
  margin-bottom: 2px;
  padding: 0;
  font-weight: 300;
  font-size: 14px;
}

.technologies-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  padding-top: 10px;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 500;
}

.card:has(a.link-button:focus) {
  transform: scale(1.05);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}

.card .title-container nav.links li a.link-button:focus {
  outline: 3px solid var(--card-color-primary);
  outline-offset: 2px;
}
