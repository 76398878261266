.home {
    display: grid;
    grid-template-areas: 
    "intro-title"
    "title"
    "buttons"
    "titles";
    grid-template-rows: 1fr 2fr 1fr 1fr;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
}

.socials {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.8rem;
    left: 0;
    position: fixed;
    height: 100%;
    width: 10%;
    top: 0;
    transition: transform 0.3s cubic-bezier(.04,.71,.62,1.35);
}

.line {
    height: 100%;
    background: rgba(0, 55, 83, 0.5);
    width: 1px;
}

.separator {
    background: rgba(0, 55, 83, 0.5);
    width: 1px;
    height: 0.5em;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

.line.before {
    height: 100%;
}

.line.after {
    height: 100%;
}

.socials .buttons {
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.socials .buttons a {
    text-decoration: none;
    transition: transform 0.3s cubic-bezier(.04,.71,.62,1.35);
}

.socials .buttons a:hover {
    transform: scale(1.2);
}

.svg-inline--fa path {
    fill: var(--color-primary) !important;
}

@media (width < 600px) {

    .socials {
        grid-row: 1;
        grid-column: 1;
        position: relative;
        flex-direction: row;
        width: 100%;
        height: 3vh;
        padding-top: 2vh;
    }
    .socials .buttons {
        font-size: 1.5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-inline: 0.5rem;
    }
    .svg-inline--fa path {
        fill: var(--color-black) !important;
    }
    .separator {
        background: var(--color-black);
        height: 1px;
        width: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .line.before,
    .line.after {
        height: 1px;
        width: 100%;
        background: var(--color-black);
    }

    .socials .buttons a {
        transform: scale(1.25);
    }
    .socials .buttons a:hover {
        transform: scale(1.5);
    }
}

.home h1 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 5em;
    margin: 0;
    line-height: 0.8em;
    letter-spacing: -10px;
}

@media screen and (min-width: 0px) {
    .home h1 {
        font-size: 3rem;
        letter-spacing: -5px;
    }
}

@media screen and (min-width: 320px) {
    .home h1 {
        font-size: 3rem;
        letter-spacing: -3px;
    }
    .social {
        width: 0;
    }
    .spacer {
        width: 0;
    }
}

@media (min-width: 500px) {
    .home h1 {
        font-size: 4rem;
        letter-spacing: -5px;
    }
}

@media (width<600px) {
    .App .full-page {
        height:  calc(100vh - 5vh);
    }
}

@media (min-width: 800px) {
    .home h1 {
        font-size: 5rem;
        letter-spacing: -7px;
    }
}

@media (min-width: 1000px) {
    .home h1 {
        font-size: 7rem;
        letter-spacing: -10px;
    }
}

.home .intro-title {
    text-shadow: none;
    color: var(--color-white);
    text-align: center;
    font-size: 2rem;
    font-weight: 100;
    letter-spacing: -1px;
}

.content .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: auto;
    width: 100%;
    max-width: 700px;
}

.buttons .resume-button,
.buttons .contact-button {
    border: 2px solid var(--color-black);
    text-decoration: none;
    border-radius: 5px;
    padding: 2% 4%;
    font-weight: 300;
    color: var(--color-white);
    background-color: var(--color-black);
    letter-spacing: normal;
    text-align: center;
}

.buttons .resume-button:hover {
    background-color: transparent;
}

.buttons .contact-button:hover {
    background-color: transparent;
}

.home .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 4em;
    font-weight: 900;
    color: var(--color-black);
    height: 250px;
    flex-grow: 2;
}

.home .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1 1;
    flex-grow: 5;
}

.home .titles {
    align-items: center;
    color: var(--color-white);
    display: flex;
    flex-direction: row;
    font-size: 1.5em;
    font-weight: 300;
    justify-content: center;
    letter-spacing: -1px;
    margin: auto;
    text-align: center;
}

.home .titles .title {
    position: relative;
}

.home .titles p:not(:last-child)::after {
    content: "\2193";
    color: var(--color-black);
    display: inline-block;
    margin-left: 0.5em;
    position: relative;
    bottom: -0.25em;
    transition: transform 0.5s ease, opacity 0.5s ease !important;
}

.home .titles p:not(:first-child)::before {
    content: "";
    margin-right: 0.5em;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.home .titles.scrolled p:not(:last-child)::after {
    content: "/";
    color: var(--color-black);
    display: inline-block;
    position: relative;
    top: -0.1em;
    transform: rotate(-180deg);
    opacity: 1;
}

.home .titles.scrolled p:not(:first-child)::before {
    content: "/";
    color: var(--color-black);
    display: inline-block;
    position: relative;
    bottom: -0.1em;
    transform: rotate(0deg);
    opacity: 1;
}

.home .titles p:not(:last-child)::after {
    transform: rotate(0deg);
    opacity: 1;
}

.home .titles p:not(:first-child)::before {
    transform: rotate(-90deg);
    opacity: 0;
}

@media screen and (max-width: 865px) {
    .App {
        grid-template-columns: 5% 90% 5%;
    }
    .home .titles {
        flex-direction: column;
    }
    .home .titles {
        text-align: left !important;
        align-items: flex-start;
    }
    .home .titles p {
        margin: 0;
        margin-top: 10px;
    }
    .home .titles p::after,
    .home .titles.scrolled p:not(:last-child)::after
    {
        display: none !important;
    }
    .home .titles p::before,
    .home .titles p:not(:first-child)::before,
    .home .titles.scrolled p:not(:first-child)::before
    {
        content: "/ /";
        color: var(--color-black);
        position: relative;
        display: inline-block;
        margin-right: 0.5em;
        bottom: -0.1em;
        opacity: 1;
        transform: inherit;
        transition: none;
    }
    #action-buttons {
        width: 75%;
    }
}

@media screen and (max-width: 500px) {
    #action-buttons {
        width: 100%;
    }
}

#main-img {
    max-height: 60vh;
}

.title .first {
    margin-right: 2rem;
    cursor: default;
}

.title .last {
    margin-left: 2.2rem;
}

.title .first {
    line-height: 0.8em;
    letter-spacing: -10px;
}

.title .last {
    line-height: 0.8em;
    letter-spacing: -10px;
}

.socials .buttons a:focus,
.buttons .resume-button:focus,
.buttons .contact-button:focus {
    outline: 3px solid var(--color-black);
    outline-offset: 2px;
}
